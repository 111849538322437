import React from 'react'
import { Outlet } from 'react-router-dom'
import Styles from "./assets/css/common.module.scss";
import Navbar from './pages/layouts/Navbar';
import Footer from './pages/layouts/Footer';


const App = () => {
  return (
    <>
      <div className={`${Styles.main}`}>
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </>
  )
}

export default App
