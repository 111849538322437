import React from 'react';
import Styles from "../assets/css/home.module.scss";
import Girl from "../assets/images/girl_orignal.png";
import GirlLayer from "../assets/images/girl_front.png";
import BecomeArrow from "../assets/images/ArrowRight.png"
import ArtistLeptopImage from "../assets/images/artist_leptop.png"
import ArtistLeptopBgImage from "../assets/images/artist_leptop_bg.png"
import InfoBg from "../assets/images/infoImageBg.png"
import Info from "../assets/images/infoImage.png"
import PlayStore from "../assets/images/playStore.png"
import AppStore from "../assets/images/appStore.png"
import MobileMokup from "../assets/images/mobile.png"
import MobileBg from "../assets/images/mobileBg.png"
import InfoDollar from "../assets/images/Dollar_Circle.png"
import InfoCheck from "../assets/images/Check_Info.png"
import InfoCircle from "../assets/images/info_circle.png"

const Home = () => {
  return (

    <div className={`${Styles.home}`}>
    
      {/* Description section started */}
      <div className={`${Styles.description}`}>
          <div className={`${Styles.description_details}`}>
            <h2><span className={`${Styles.bold}`}>Groove </span>to the Move,</h2>
            <h2><span className={`${Styles.semiBold}`}>Play </span>What You <span className={`${Styles.bold}`}>Love!</span></h2>
            <p>Mawj's got your back with the beats you crave, whenever, wherever! Chilling, working, partying? We’ve got playlists for every special occasion. Or get creative and build your own vibe. Hit play and let Mawj take over the vibe game!</p>
          </div>
          <div className={`${Styles.description_image}`}>
            <img src={Girl} alt="not supported" />
            <div className={`${Styles.description_image_layer}`}>
              <img src={GirlLayer} alt="not supported" />
            </div>
          </div>
      </div>
      {/* Description section ended */}


      {/* Artist section started */}
      <div className={`${Styles.artist}`}>
        <div className={`${Styles.container}`}>
          <div className={`${Styles.artist_details}`}>
            <h2>Get Discovered on Mawj!</h2>
            <p>Ready to share your sound with the world? Mawj is the place for artists like YOU to shine. Whether you’re a seasoned pro or just getting started, we’re all about helping you reach new ears. Click the below button to start your awesome artist journey. Let Mawj help you make waves in the music scene!</p>
            <a href='http://mawj.studio/'>Become on Artist <img src={BecomeArrow} alt="" /></a>
          </div>
          <div className={`${Styles.artist_image}`}>
            <div className={`${Styles.artist_laptop}`}>
              <img src={ArtistLeptopImage} alt="not supported" />
            </div>
            <div className={`${Styles.artist_laptop_bg}`}>
              <img src={ArtistLeptopBgImage} alt="not supported" />
            </div>
          </div>
        </div>
      </div>
      {/* Artist section ended */}



      {/* Info section started */}
      <div className={`${Styles.info}`}>
        <div className={`${Styles.container}`}>
          <div className={`${Styles.info_image}`}>
            <div className={`${Styles.info_main_img}`}>
              <img id='infoImg' src={Info} alt="not supported" />
            </div>
          </div>
          <div className={`${Styles.info_details}`}>
            <div className={`${Styles.info_one}`}>
              <h3><img src={InfoDollar} alt="not supported" /> Lowest Price</h3>
              <p>Enjoy top-quality music without breaking the bank. Mawj offers the best tunes at unbeatable prices.</p>
            </div>

            <div className={`${Styles.info_two}`}>
              <h3><img src={InfoCircle} alt="not supported" /> High Resolution Music</h3>
              <p>Experience every note in stunning clarity. Mawj delivers high-resolution sound for the ultimate listening pleasure.</p>
            </div>

            <div className={`${Styles.info_three}`}>
              <h3><img src={InfoCheck} alt="not supported" /> Available Everywhere</h3>
              <p>Your music, anytime, anywhere. Mawj is ready to rock on all your devices, wherever you go.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Info section ended */}



      {/* Mobile section started */}
      <div className={`${Styles.mobile}`}>
        <div className={`${Styles.container}`}>
          <div className={`${Styles.mobile_details}`}>
            <h2>Mawj: Future of Music Streaming</h2>
            <p className={`${Styles.mobile_description}`}>Mawj, the ultimate music streaming app designed to elevate your listening experience. With Mawj, you get access to the highest quality sound at the lowest prices, ensuring that every beat hits just right. Enjoy crystal-clear, high-resolution music that brings your favorite tracks to life with incredible detail. Plus, Mawj is available everywhere—whether you're on your phone, tablet, or computer, your music is always within reach. Feel the power of endless music with Mawj—immerse yourself in a never-ending wave of beats and melodies!</p>
            <div className={`${Styles.buttons}`}>
              <a href='http://mawj.studio'>
                <img src={AppStore} alt="not supported" />
                <p><span>Download By</span><br /><span>App Store</span></p>
              </a>
              <a href='http://mawj.studio'>
                <img src={PlayStore} alt="not supported" />
                <p><span>Get it on</span><br /><span>Google Play</span></p>
              </a>
            </div>
          </div>
          <div className={`${Styles.mobile_image}`}>
            <div className={`${Styles.mobile_laptop}`}>
              <img src={MobileMokup} alt="not supported" />
            </div>
            <div className={`${Styles.mobile_laptop_bg}`}>
              <img src={MobileBg} alt="not supported" />
            </div>
          </div>
        </div>
      </div>
      {/* Mobile section ended */}


      


    </div>

  )
}

export default Home