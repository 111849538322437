import React from 'react';
import Styles from "../../assets/css/footer.module.scss";

const Footer = () => {
  return (
    <div className={`${Styles.footer}`}>
      <div className={`${Styles.container}`}>
        <p>MAWJ © 2024. All Rights Reserved.</p>
      </div>
    </div>
  )
}

export default Footer
