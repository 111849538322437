import React from 'react';
import Styles from "../../assets/css/navbar.module.scss";
import Logo from "../../assets/images/logo.png";

const Navbar = () => {
  return (
    <div className={`${Styles.nav}`}>
        <div className={`${Styles.container}`}>
            <div className={`${Styles.logo}`}>
                <img src={Logo} alt="not supported" />
            </div>
        </div>
    </div>
  )
}

export default Navbar
